@function strip-unit($value) {
  @return $value / ($value * 0 + 1);
}

@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-font-size);
  $u4: unit($max-font-size);

  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
    & {

      font-size: $min-font-size;
      @media screen and (min-width: $min-vw) {
        font-size: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
      }

      @media screen and (min-width: $max-vw) {
        font-size: $max-font-size;
      }
    }
  }
}

$min_width: 320px;
$max_width: 1200px;
$min_font: 16px;
$max_font: 22px;

//colors
$color_lightning_yellow_approx: #f7ca18;
$color_haute_1_couture: #2D3C5B;
$halfway_space: #070D1D;
$color_fuscous_gray_approx: #555;
$color_bunker_approx: #151515;
$gallery: #efefef;
$color_cararra_approx: #ececec;
$color_log_cabin_approx: #222;
$white: #fff;
$black: #000;
$grey-2: #302f2f;

@keyframes pop {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes popLeft {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes byBottom {
  0% {
    transform: translateY(150%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;

  }
}

@keyframes linkAfter {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

html {
  @include fluid-type($min_width, $max_width, $min_font, $max_font);
  width: 100%;
}

a {
  color: inherit;
  text-decoration: inherit;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Ubuntu', sans-serif;
  width: 100%;
  background-color: $color_haute_1_couture;
}

::selection {
  background-color: $color_lightning_yellow_approx;
  color: $color_haute_1_couture;
}

#root {
  width: 100%;
}

.content, .content-wrapper {
  background-color: $color_haute_1_couture;
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-content: center;
}

.main-page {
  position: fixed;
  bottom: 0;
  left: 0;
  &__title {
    width: 100%;
    align-self: flex-end;
    padding-bottom: 80px;

    transform: translateY(-50px);
    margin: 0 15px 0 15px;
    overflow: hidden;
    animation: byBottom 1s ease both;

    @media screen and (min-width: $min-width) {
      margin: 0 calc(15px + #{strip-unit(50px - 15px)} * ((100vw - #{$min-width}) / #{strip-unit($max-width - $min-width)})) 0 calc(15px + #{strip-unit(80px - 15px)} * ((100vw - #{$min-width}) / #{strip-unit($max-width - $min-width)}));
    }

    @media screen and (min-width: $max-width) {
      margin: 0 auto 0 80px;
    }

  }

  &__name {
    display: inline-block;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 900;
    text-transform: uppercase;
    color: $color_cararra_approx;
    line-height: .75;
    width: 100%;
    margin: auto;
    letter-spacing: -.1rem;
    animation: byBottom 1.5s ease both;
    @include fluid-type($min_width, $max_width, 30px, 110px);

  }

  &__desc {
    @include fluid-type($min_width, $max_width, 12px, 30px);
    display: inline-block;
    text-transform: lowercase;
    transform: translateX(-100%);
    animation: byBottom 1s ease both;
    font-weight: 300;
    margin-left: 0.2rem;
    color: $color_cararra_approx;
  }
  &__text {
    @include fluid-type($min_width, $max_width, 12px, 30px);
    display: inline-block;
    text-transform: lowercase;
    color: $color_haute_1_couture;
    letter-spacing: .1rem;
    font-weight: 300;
    transform: translateX(-100%);
    animation: byBottom 2s ease both;
    margin-left: 0.2rem;
    position: relative;
    padding: 0 0.45em;
    line-height: 1.5;
    margin-top: 10px;

    &::after {
      content: "";
      height: 100%;
      background-color: $color_lightning_yellow_approx;
      position: absolute;
      z-index: -1;
      left: 0;
      width: 0;
      animation: linkAfter 0.5s 1.25s ease both;
    }
  }
}

.menu {
  height: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  position: absolute;
  left: 0;
  transform: translateX(-100%);
  transition: .5s ease-in-out;
  &--open {
    transform: translateX(0%);
  }
  &__container {
    margin: 0 1rem 0 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  &__item {
    font-size: 20px;
    width: 60px;
    height: 50px;
    line-height: 50px;
    margin: 0 3px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: scale(1) translateX(-150%);

    &:last-child {
      margin-right: 16px;
    }

    &:first-child {
      animation: popLeft 0.5s 0.6s 1 forwards;
    }
    &:nth-child(2) {
      animation: popLeft 0.5s 0.75s 1 forwards;
    }
    &:nth-child(3) {
      animation: popLeft 0.5s 1s 1 forwards;
    }
    &:nth-child(4) {
      animation: popLeft 0.5s 1.25s 1 forwards;
    }

  }
  &__link{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    outline: none;
    text-decoration: none;
    color: transparent;
    text-align: center;
    font-size: 0;
    height: 100%;
    &:before {
      color: $white;
      transition: color 0.2s;
      font-variant: normal;
      text-transform: none;
      speak: none;
      text-decoration: none;
      width: 1em;
      font-size: 1.5rem;
    }
    &:hover:before {
      color: $color_lightning_yellow_approx;
      font-size: 2rem;
      transition: .3s ease-in;
    }
    &:focus:before {
      color: $color_lightning_yellow_approx;
    }
  }

}

.social-media {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 90px;
  border-width: 0;
  z-index: 10;
  &__container {
    display: flex;
    margin: 0 1rem 0 0;
    padding: 0;
    list-style: none;
  }
  &__item {
    font-size: 20px;
    width: 60px;
    height: 50px;
    line-height: 50px;
    margin: 0 3px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: scale(1) translateY(150%);
    cursor: pointer;

    &:last-child {
      margin-right: 16px;
    }
    &:first-child {
      animation: pop 0.5s 0.6s 1 forwards;
    }
    &:nth-child(2) {
      animation: pop 0.5s 0.75s 1 forwards;
    }
    &:nth-child(3) {
      animation: pop 0.5s 1s 1 forwards;
    }
    &:nth-child(4) {
      animation: pop 0.5s 1.25s 1 forwards;
    }

  }
  &__link {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    outline: none;
    text-decoration: none;
    color: transparent;
    text-align: center;
    font-size: 0;
    height: 100%;
    &:before {
      color: $white;
      transition: color 0.2s;
      font-variant: normal;
      text-transform: none;
      speak: none;
      text-decoration: none;
      width: 1em;
      font-size: 1.5rem;
    }
    &:hover:before {
      color: $color_lightning_yellow_approx;
      font-size: 2rem;

    }
    &:focus:before {
      color: $color_lightning_yellow_approx;
    }
  }
}
